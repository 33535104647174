import { Menu, Search, X } from "lucide-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import SwitchTabs from "./components/switch";
import API from "../../api";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { count } from "console";

const SwitchTabContent = [
  {
    name: "1d",
    value: "0",
  },
  {
    name: "2d",
    value: "1",
  },
  {
    name: "3d",
    value: "2",
  },
  {
    name: "7d",
    value: "6",
  },
  {
    name: "1m",
    value: "29",
  },
];

const RecentNotes = ({ className }: { className?: string }) => {
  const [period, setPeriod] = useState("0");
  // const [totalTickets, setTotalTicekts] = useState(0);
  const [searchParam, setSearchParam] = useState<string>("");
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(
    ["RecentBusinessNotes", period],
    async () => {
      const response = await API.get(
        `/notes/recentBusinessNotes/?period=${period}`
      );
      return response.data?.sort(
        (a: any, b: any) => b.Note?.ROWID - a.Note?.ROWID
      );
    }
  );

  const searchFilteredData = useMemo(() => {
    if (searchParam) {
      return data?.filter((note: any) => {
        return note?.Business?.name
          .toLowerCase()
          .includes(searchParam?.toLowerCase());
      });
    } else {
      return data;
    }
  }, [data, searchParam]);

  const totalTickets = useMemo(() => {
    let count = 0;
    data?.map((note: any) => {
      count = count + Number(note?.Note?.ROWID);
    });
    return count;
  }, [data]);

  return (
    <aside
      className={cn(
        className,
        `sticky top-[72px] xl:w-[18vw] h-[calc(100vh-72px)] lg:w-[36vw] bg-primary`
      )}
    >
      <div className="flex flex-col gap-2 ">
        <SwitchTabs
          tablist={SwitchTabContent}
          value="0"
          onChange={(e) => {
            setPeriod(e);
          }}
        />
        <div className="flex gap-2 flex-col text-white h-[85vh] overflow-y-auto">
          {
            <div className="flex justify-between gap-2 border-b text-[14px] px-4 py-1  border-[#43225c]">
              <Search size={24} className="text-gray-400" />
              <input
                placeholder="Search ..."
                type="text"
                value={searchParam}
                onChange={(e) => setSearchParam(e.target.value)}
                className="bg-transparent border-none focus:outline-none w-full"
              />
              {searchParam?.length > 0 && (
                <button onClick={() => setSearchParam("")}>
                  <X size={20} className="text-gray-400" />
                </button>
              )}
            </div>
          }
          <div className="flex justify-between border-b text-[14px] px-4 py-1  border-[#43225c]">
            <p className="cursor-pointer">Total</p>
            <p>{totalTickets}</p>
          </div>
          {isLoading && <p className="text-[14px] px-4 py-1 ">loading...</p>}

          {searchFilteredData?.length !== 0 ? (
            searchFilteredData?.map((note: any, index: number) => {
              return (
                <div
                  key={index}
                  className="flex justify-between border-b text-[14px] px-4 py-1  border-[#43225c]"
                >
                  <p
                    className="cursor-pointer line-clamp-1"
                    onClick={() =>
                      navigate(
                        `/app/business/${note?.Note?.businessID}?tab=TIMELINE`
                      )
                    }
                  >
                    {note?.Business?.name}
                  </p>
                  <p>{note?.Note.ROWID}</p>
                </div>
              );
            })
          ) : (
            <p className="text-[14px] px-4 py-1 ">
              Oops ! No Recent Ticket found
            </p>
          )}
        </div>
      </div>
    </aside>
  );
};

export default RecentNotes;
