import NotesOverviewItem from "./NotesOverviewItem";
import InfiniteScroll from "react-infinite-scroll-component";
import { ITicketOverview } from "constants/Interfaces/notesInterface";
import * as HoverCard from "@radix-ui/react-hover-card";
import NotesOverviewItemContent from "./NotesOverviewItemContent";
import { PSTtoLocal } from "utils/dateConverter";
import { useEffect, useState } from "react";
import { useRef } from "react";

// Only show notes within last 24 horus
// Manually show all timings 12AM, 1AM, 2AM, .... 11PM, 12AM

const timeSlots = [
  { start: 0, end: 4, label: "12AM - 4AM" },
  { start: 4, end: 8, label: "4AM - 8AM" },
  { start: 8, end: 12, label: "8AM - 12PM" },
  { start: 12, end: 16, label: "12PM - 4PM" },
  { start: 16, end: 20, label: "4PM - 8PM" },
  { start: 20, end: 24, label: "8PM - 12AM" },
];

const NotesOverview = ({
  notesList,
  handleClick,
}: {
  notesList: ITicketOverview[];
  handleClick: (id: string) => void;
}) => {
  const [minNoteHeight,setMinNoteHeight]=useState(0)
  const divRef = useRef<HTMLDivElement | null>(null);

  
  const filteredNotes = notesList.filter((note) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return PSTtoLocal(note.updatedAt) > today;
    // return true;
  });
  useEffect(() => {
    if (divRef.current && filteredNotes.length > 0) {
      const height = Math.floor(divRef.current.clientHeight /(filteredNotes.length*16));
      setMinNoteHeight(height);
    }
  }, [filteredNotes]);


 
  const groupedNotes = timeSlots.map((slot) => {
    const notesInSlot = filteredNotes.filter((note) => {
      const updatedAt = PSTtoLocal(note.updatedAt);
      return (
        updatedAt.getHours() >= slot.start && updatedAt.getHours() < slot.end
      );
    });
    return { ...slot, notes: notesInSlot };
  });

  return (

    <aside ref={divRef} className="flex flex-col justify-between sticky top-[72px] min-w-[9rem] py-2 gap-1.5 bg-white rounded-t-md mr-4 overflow-auto" style={{ height: "calc(100vh - 72px)"}} id="NotesOverviewDiv">
      <InfiniteScroll
        scrollThreshold={0.8}
        loader={
          <div className="h-[20vh] bg-transparent flex items-center justify-center w-full">
            <div className="loader"></div>
          </div>
        }
        dataLength={timeSlots.length}
        hasMore={false}
        next={() => { }}
        scrollableTarget="NotesOverviewDiv"
      >

        {[...groupedNotes].reverse().map((slot) => (
          slot.notes.length === 0 ? (
            <div key={slot.label} />
          ) : (
            <div key={slot.label} className="flex max-h-max gap-3 border-b-2 pb-2">
              <h2 className="pl-1 flex text-sm flex-col items-center justify-center text-gray-500 w-1/2">
                <span>{slot.label.split("-")[1]}</span>
                <span>-</span>
                <span>{slot.label.split("-")[0]}</span>
              </h2>
              <div className="flex flex-col justify-center w-full gap-0.5">
                {[...slot.notes].reverse().map((note) => (
                  <HoverCard.Root key={note.id}>
                    <HoverCard.Trigger onClick={() => handleClick(note.id)}>
                      <NotesOverviewItem
                        note={note}
                        numberOfItems={slot.notes.length}
                        minNoteHeight={minNoteHeight}
                      />
                    </HoverCard.Trigger>
                    <HoverCard.Portal>
                      <HoverCard.Content side="left" align="start" className="z-50">
                        <NotesOverviewItemContent note={note} />
                      </HoverCard.Content>
                    </HoverCard.Portal>
                  </HoverCard.Root>
                ))}
              </div>
            </div>
          )
        ))}

      </InfiniteScroll>
    </aside>

  );
};

export default NotesOverview;