import Navbar from "features/navbar";
import React from "react";

const LayoutWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex flex-col h-full w-screen p-0 m-0">
      <Navbar className="sticky top-0 z-20 bg-primary w-full py-4 px-4 md:px-8 h-[72px]" />
      <div className="flex-grow flex justify-between h-fit">{children}</div>
    </div>
  );
};

export default LayoutWrapper;
